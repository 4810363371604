import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule, NgbPopoverModule, NgbTimepickerModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgWizardModule } from 'ng-wizard3';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslocoRootModule } from 'src/app/transloco-root/transloco-root.module';
import { ngWizardConfig } from '../../constants/ng-wizard-config.constant';
import { StandardContainerComponent } from './standard-container/standard-container.component';
import { StandardDataManagementViewComponent } from './standard-data-management-view/standard-data-management-view.component';
import { StandardDatetimePickerComponent } from './standard-datetime-picker/standard-datetime-picker.component';
import { StandardDropdownCardComponent } from './standard-dropdown-card/standard-dropdown-card.component';
import { StandardImagePreviewComponent } from './standard-image-preview/standard-image-preview.component';
import { StandardListviewComponent } from './standard-listview/standard-listview.component';
import { StandardTextboxComponent } from './standard-textbox/standard-textbox.component';
import { StandardToggleSwitchComponent } from './standard-toggle-switch/standard-toggle-switch.component';
import { StandardToolbarComponent } from './standard-toolbar/standard-toolbar.component';

@NgModule({
  declarations: [
    StandardToggleSwitchComponent,
    StandardTextboxComponent,
    StandardContainerComponent,
    StandardToolbarComponent,
    StandardListviewComponent,
    StandardDropdownCardComponent,
    StandardDataManagementViewComponent,
    StandardDatetimePickerComponent,
    StandardImagePreviewComponent,
  ],
  imports: [
    NgbTooltipModule,
    CommonModule,
    FormsModule,
    NgxDatatableModule,
    NgxSpinnerModule,
    RouterModule,
    NgWizardModule.forRoot(ngWizardConfig),
    NgbDatepickerModule,
    NgbPopoverModule,
    NgbTimepickerModule,
    ReactiveFormsModule,
    TranslocoRootModule,
  ],
  exports: [
    StandardToggleSwitchComponent,
    StandardTextboxComponent,
    StandardContainerComponent,
    StandardToolbarComponent,
    StandardListviewComponent,
    StandardDropdownCardComponent,
    StandardDataManagementViewComponent,
    StandardDatetimePickerComponent,
    StandardImagePreviewComponent,
    NgxDatatableModule,
  ],
})
export class StandardComponentsModule {}
