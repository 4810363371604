/**
 * STEGO Label Manager API
 * The STEGO Label Manager API handles all portal functionality.
 *
 * The version of the OpenAPI document: v1
 * Contact: info@stego.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type PrinterType = 'CabPrinter' | 'CabLaser' | 'ZebraPrinter' | 'GravotechLaser' | 'KtoKtsLaser' | 'SVGPrinter';

export const PrinterType = {
  CabPrinter: 'CabPrinter' as PrinterType,
  CabLaser: 'CabLaser' as PrinterType,
  ZebraPrinter: 'ZebraPrinter' as PrinterType,
  GravotechLaser: 'GravotechLaser' as PrinterType,
  KtoKtsLaser: 'KtoKtsLaser' as PrinterType,
  SVGPrinter: 'SVGPrinter' as PrinterType
};
